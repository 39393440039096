import { Grid } from "@mui/material";
import React from "react";
import PageHeaderBlack from "../components/typography/PageHeaderBlack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import HomeHeroLg from "../components/heroes/HomePageHeroLg";
import HomeHeroSm from "../components/heroes/HomePageHeroSm";
import WavingGuy from "../images/welcome-page-waving-guy@2x.png";
import SmilingGirl from "../images/welcome-page-smiling-girl@2x.png";
import Para from "../components/typography/Para";

import "../layout.css";

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "2.5rem",
  textAlign: "center",
  color: "#000",
  display: "inline-flex",
  lineHeight: "2rem",
  margin: 0,
};

export default function Contact() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#89c6e2" }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "80%" },
          m: "auto",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#ffc423",
            border: "5px solid #333333",
            padding: "1.5rem",
            mx: "3rem",
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center", marginBottom: "1rem" }}>
            <Typography style={PageHeader} sx={{ textAlign: "center" }}>
              {t("contact-page-header-line1")} <br />{" "}
              {t("contact-page-header-line2")}
            </Typography>
          </Grid>
          <Para>{t("contact-page-para1")}</Para>
          <br />
          <Para>{t("contact-page-para2")}</Para>
          <br />
          <Para>{t("contact-page-para3")}</Para>
          <br />
        </Grid>
      </Grid>
    </Grid>
  );
}
