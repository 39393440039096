import * as React from "react";

import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const PageHeadingBlack = styled(Typography)`
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000;
  line-height: 2rem;
`;

// display: inline-flex;

export default function PageHeaderBlack(props) {
  return (
    <PageHeadingBlack
      sx={{
        fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
        textAlign: { xs: "center", sm: "left" },
      }}
    >
      {props.children}
    </PageHeadingBlack>
  );
}
