import * as React from "react";

import Grid from "@mui/material/Grid";
import "../../layout.css";
import HomePageHero from "../../images/studyguide-homepage-hero-no-text.png";
import { useTranslation } from "react-i18next";

export default function HomeHeroLg() {
  const { t } = useTranslation();

  const ResponsiveImg = {
    width: "100%",
    height: "auto",
  };

  return (
    <Grid container className="">
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "marvin-round",
          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.6rem" },
          color: "#4d4d4d",
          textAlign: "center",
          position: "relative",
          // top: "45%",
        }}
      >
        <img style={ResponsiveImg} src={HomePageHero} alt="background" />
        <h1 className="centered" style={{ margin: "0px" }}>
          {t("home-page-hero-lg-line1")}
        </h1>
        <h1 className="centered-lower" style={{ margin: "0px" }}>
          {t("home-page-hero-lg-line2")}
        </h1>
        <h1 className="centered-lowest" style={{ margin: "0px" }}>
          {t("home-page-hero-lg-line3")}
        </h1>
      </Grid>
    </Grid>
  );
}
