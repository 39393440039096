import { Grid } from "@mui/material";
import React, { useState } from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Icon5 from "../images/Icon5.png";
import Icon6 from "../images/Icon6.png";
import Icon7 from "../images/Icon7.png";
import Icon8 from "../images/Icon8.png";
import Icon9 from "../images/Icon9.png";
import Icon10 from "../images/Icon10.png";

import SmilingGuy from "../images/study-overview-smiling-guy@2x.png";

import "../layout.css";

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontSize: "2.5rem",
  color: "#f58020",
  margin: "0px",
};

const ResponsiveImg = {
  width: "75%",
  height: "auto",
};

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

export default function StudyOverview() {
  const { t } = useTranslation();
  
  const [studyTreatmentPeriod, setStudyTreatmentPeriod] = useState(false);
  const [followUp, setFollowUp] = useState(false);

  function toggleStudyTreatmentPeriod() {
    setStudyTreatmentPeriod(!studyTreatmentPeriod);
  }

  function toggleFollowUp() {
    setFollowUp(!followUp);
  }

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#89c6e2" }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "white",
            border: "5px solid #333333",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <h1 style={PageHeader}>{t("study-overview-title")}</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className=""
          sx={{
            backgroundColor: "#fff",
            height: "100px",
            border: "3px dashed #135e77",
            marginTop: { xs: "2rem" },
            marginBottom: "1rem",
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              padding: "1rem",
              color: "#14b0be",
              fontFamily: "Arial",
              fontSize: "1.25rem",
            }}
          >
            {t("study-overview-blurb-text")}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <img className="responsive-img" src={SmilingGuy} />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={9}
            sx={{
              textAlign: "center",
              backgroundColor: "#fff",
              border: "5px solid #000",
              padding: "1rem",
              paddingRight: "2rem",
              paddingLeft: "2rem",
              mx: "auto",
              mt: "2rem",
            }}
          >
            <Para>
              <i>{t("study-overview-box-italic-text")}</i>
            </Para>
            <Grid item sx={{ marginTop: "1rem" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: "#f58020",
                  "&:hover": {
                    backgroundColor: "#ea7a1d",
                  },
                }}
                onClick={toggleStudyTreatmentPeriod}
              >
                <Typography
                  sx={{
                    fontFamily: "brandon-grotesque",
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    textTransform: "none",
                  }}
                >
                  {t("study-overview-button1-text")}
                </Typography>
              </Button>
            </Grid>
            {studyTreatmentPeriod ? (
              <Grid item sx={{ marginTop: "1rem", textAlign: "left" }}>
                <ul>
                  <li style={ListItem}>
                    {t("study-overview-button1-list-item1")}
                  </li>
                  <li style={ListItem}>
                    {t("study-overview-button1-list-item2")}
                  </li>
                  <ul>
                    <li style={ListItem}>
                      {t("study-overview-button1-list-item2a")}
                    </li>
                  </ul>
                  <li style={ListItem}>
                    {t("study-overview-button1-list-item3")}
                  </li>
                  <ul>
                    <li style={ListItem}>
                      {t("study-overview-button1-list-item3a")}
                    </li>
                  </ul>
                  <li>{t("study-overview-button1-list-item4")}</li>
                  <ul>
                    <li style={ListItem}>
                      {t("study-overview-button1-list-item4a")}
                    </li>
                  </ul>
                  <li style={ListItem}>
                    {t("study-overview-button1-list-item5")}
                  </li>
                  <li style={ListItem}>
                    {t("study-overview-button1-list-item6")}
                  </li>
                </ul>
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid item sx={{ marginTop: "1rem" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: "#f58020",
                  "&:hover": {
                    backgroundColor: "#ea7a1d",
                  },
                }}
                onClick={toggleFollowUp}
              >
                <Typography
                  sx={{
                    fontFamily: "brandon-grotesque",
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    textTransform: "none",
                  }}
                >
                  {t("study-overview-button2-text")}
                </Typography>
              </Button>
            </Grid>
            {followUp ? (
              <Grid item sx={{ textAlign: "left" }}>
                <ul>
                  <li style={ListItem}>
                    {t("study-overview-button2-list-item1")}
                  </li>
                  <li style={ListItem}>
                    {t("study-overview-button2-list-item2")}
                  </li>
                </ul>
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              <Para>
                {t("study-overview-box-bottom-text")}
                <Link to="/study-video">
                  {t("study-overview-box-bottom-text-link")}
                </Link>
                  {t("study-overview-box-bottom-text-period")}
              </Para>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
