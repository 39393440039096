import * as React from "react";
import { useState, useEffect } from "react";
import "./Navbar.css";

import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DrawerLink from "./DrawerLink";
import PeriscopeLogo from "../../images/periscope-logo.png";

const NavbarLink = {
  fontFamily: "marvin-round",
  fontSize: "15px",
  color: "#4D4D4E",
  textDecoration: "none",
  marginLeft: "1rem",
  marginRight: "2.5rem",
};

const NavbarLinkActive = {
  fontFamily: "marvin-round",
  fontSize: "15px",
  color: "#4D4D4E",
  backgroundColor: "#fcbb86",
  textDecoration: "none",
  padding: ".5rem",
  marginLeft: "1rem",
  marginRight: "2.5rem",
};

const DrawerBtn = {
  textDecoration: "none",
  backgroundColor: "transparent",
  border: "none",
  width: "100%",
};

const ResponsiveImg = {
  width: "100px",
  height: "100px",
};

export default function MainNav() {
  const { t } = useTranslation();
  const currentPath = useLocation();

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(currentPath.pathname);
  });

  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const NavbarLinks = [
    {
      name: t("welcome-navlink"),
      to: "/welcome",
    },
    {
      name: t("study-overview-navlink"),
      to: "/study-overview",
    },
    {
      name: t("what-to-do-navlink"),
      to: "/what-to-do",
    },
    {
      name: t("study-clinic-visits-navlink"),
      to: "/study-clinic-visits",
    },
    {
      name: t("contact-navlink"),
      to: "/contact",
    },
  ];

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#fff", boxShadow: 0 }}>
      <Container maxWidth="lg">
        <Toolbar>
              <Link to="/">
                <img
                  style={ResponsiveImg}
                  alt="Periscope Logo"
                  src={PeriscopeLogo}
                  />
              </Link>
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                width: "auto",
                fontWeight: 700,
                display: { xs: "none", md: "block" },
                textAlign: "center",
              }}
            >
            {NavbarLinks.map((a) => {
              return (
                <Link
                  style={activeLink === a.to ? NavbarLinkActive : NavbarLink}
                  to={a.to}
                >
                  {a.name}
                </Link>
              );
            })}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{
              mr: 2,
              color: "#4d4d4e",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
              },
            }}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box
              sx={{
                p: 2,
                height: 1,
                backgroundColor: "#fff",
              }}
            >
              {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
              <IconButton sx={{ mb: 2 }}>
                <CloseIcon onClick={toggleDrawer(false)} />
              </IconButton>

              <Divider sx={{ mb: 2 }} />
              {NavbarLinks.map((a) => {
                return (
                  <button onClick={toggleDrawer(false)} style={DrawerBtn}>
                    <DrawerLink link={a.to} text={a.name} />
                  </button>
                );
              })}
            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
