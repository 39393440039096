import { Grid } from "@mui/material";
import React from "react";
import PageHeaderBlack from "../components/typography/PageHeaderBlack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import HomeHeroLg from "../components/heroes/HomePageHeroLg";
import HomeHeroSm from "../components/heroes/HomePageHeroSm";
import WavingGuy from "../images/welcome-page-waving-guy@2x.png";
import SmilingGirl from "../images/video-page-smiling-girl@2x.png";
import VideoThumb from "../images/video-thumbnail@2x.png";
import Para from "../components/typography/Para";
import VideoEng from "../videos/1Dec2021_3110-306-002_Animated ICF Video_V1_English.mp4";

import "../layout.css";

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

const ResponsiveGirlImg = {
  width: "60%",
  height: "auto",
  verticalAlign: "bottom",
};

const ResponsiveVideoImg = {
  width: "100%",
  height: "auto",
  verticalAlign: "bottom",
  border: "5px solid #333333",
};

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "1.7rem",
  textAlign: "left",
  color: "#000",
  display: "inline-flex",
  lineHeight: "2rem",
  margin: 0,
};

export default function StudyVideo() {
  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#89c6e2" }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "80%" },
          m: "auto",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ textAlign: "right", marginBottom: "0" }}
        >
          <img style={ResponsiveGirlImg} alt="Family" src={SmilingGirl} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            backgroundColor: "#48bfa2",
            height: "150px",
            border: "5px dashed #135e77",
            marginTop: { xs: "1rem", sm: "10rem" },
          }}
        >
          <Typography
            sx={{
              padding: "1rem",
              color: "White",
              fontFamily: "Arial",
              fontSize: "1.5rem",
            }}
          >
            Take a few minutes to watch this video about the study.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            textAlign: "center",
            border: "5px solid #333333",
          }}
        >
          <video style={{ width: "100%", height: "auto" }} controls key="001">
            <source src={VideoEng} type="video/mp4" />
          </video>
        </Grid>
      </Grid>
    </Grid>
  );
}
