import { Grid } from "@mui/material";
import React, { useState } from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Icon5 from "../images/Icon5.png";
import Icon6 from "../images/Icon6.png";
import Icon7 from "../images/Icon7.png";
import Icon8 from "../images/Icon8.png";
import Icon9 from "../images/Icon9.png";
import Icon10 from "../images/Icon10.png";

import GirlInFrame from "../images/girl-in-frame@2x.png";

import "../layout.css";

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontSize: "2.5rem",
  color: "#f58020",
  margin: "0px",
};

const ResponsiveImg = {
  width: "75%",
  height: "auto",
};

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

export default function WhatToDo() {
  const { t } = useTranslation();

  const [studyTreatmentPeriod, setStudyTreatmentPeriod] = useState(false);
  const [followUp, setFollowUp] = useState(false);

  function toggleStudyTreatmentPeriod() {
    setStudyTreatmentPeriod(!studyTreatmentPeriod);
  }

  function toggleFollowUp() {
    setFollowUp(!followUp);
  }

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#89c6e2" }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "white",
            border: "5px solid #333333",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <h1 style={PageHeader}>
            {t("what-to-do-header-line1")} <br /> {t("what-to-do-header-line2")}
          </h1>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: "1rem", maxWidth: { xs: "85%", sm: "70%" }, m: "auto" }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            backgroundColor: "#3da3c3",
            height: "100px",
            border: "3px dashed #135e77",
            marginTop: { xs: "2rem" },
            marginBottom: "1rem",
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              padding: "1rem",
              color: "#fff",
              fontFamily: "brandon-grotesque",
              fontWeight: 700,
              fontSize: "1.5rem",
            }}
          >
            {t("what-to-do-blurb")}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ textAlign: "center" }}>
          <img className="responsive-img" src={GirlInFrame} />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "left",
              backgroundColor: "#fff",
              border: "5px solid #000",
              padding: "1rem",
              paddingRight: "1.5rem",
              paddingLeft: "1.5rem",
              mx: "auto",
              mt: "2rem",
              mb: "2rem",
            }}
          >
            <Para>
              {t("what-to-do-box-top-line")}
            </Para>
            <ul>
              <li style={ListItem}>
            {t("what-to-do-box-list-item1")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item2")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item3")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item4")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item5")}</li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item6")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item7")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item8")}
              </li>
              <li style={ListItem}>
            {t("what-to-do-box-list-item9")}
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
