import * as React from "react";

import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const HeroHeading = styled(Typography)`
  font-family: marvin-round, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #4d4d4d;
  line-height: 2rem;
  margin-top: 1rem;
`;

export default function HeroHeader(props) {
  return (
    <HeroHeading
      sx={{
        fontSize: { xs: "1.7rem" },
        textAlign: { xs: "center" },
      }}
    >
      {props.children}
    </HeroHeading>
  );
}
