import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./typography.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { PeriscopeContextControllerProvider } from "./context";
import { useLayoutEffect } from "react";
import HomePage from "./routes/HomePage";
import Welcome from "./routes/Welcome";
import StudyOverview from "./routes/StudyOverview";
import WhatToDo from "./routes/WhatToDo";
import StudyClinicVisits from "./routes/StudyClinicVisits";
import Contact from "./routes/Contact";
import StudyVideo from "./routes/StudyVideo";
import Visit1 from "./routes/Visit1";
import Visit2 from "./routes/Visit2";
import Visit3 from "./routes/Visit3";
import Visit4 from "./routes/Visit4";
import Visit5 from "./routes/Visit5";
import Visit6 from "./routes/Visit6";
import Visit7 from "./routes/Visit7";
import Visit8 from "./routes/Visit8";
import Visit9 from "./routes/Visit9";
import Visit10 from "./routes/Visit10";
import Visit11 from "./routes/Visit11";
import Visit12 from "./routes/Visit12";
import Visit13 from "./routes/Visit13";
import Visit14 from "./routes/Visit14";
import Visit15 from "./routes/Visit15";

import { CssBaseline } from "@mui/material";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <PeriscopeContextControllerProvider>
      <CssBaseline>
        <BrowserRouter>
          <Wrapper>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<HomePage />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/study-overview" element={<StudyOverview />} />
                <Route path="/what-to-do" element={<WhatToDo />} />
                <Route
                  path="/study-clinic-visits"
                  element={<StudyClinicVisits />}
                />
                <Route path="/contact" element={<Contact />} />
                <Route path="/study-video" element={<StudyVideo />} />
                <Route path="/visit1" element={<Visit1 />} />
                <Route path="/visit2" element={<Visit2 />} />
                <Route path="/visit3" element={<Visit3 />} />
                <Route path="/visit4" element={<Visit4 />} />
                <Route path="/visit5" element={<Visit5 />} />
                <Route path="/visit6" element={<Visit6 />} />
                <Route path="/visit7" element={<Visit7 />} />
                <Route path="/visit8" element={<Visit8 />} />
                <Route path="/visit9" element={<Visit9 />} />
                <Route path="/visit10" element={<Visit10 />} />
                <Route path="/visit11" element={<Visit11 />} />
                <Route path="/visit12" element={<Visit12 />} />
                <Route path="/visit13" element={<Visit13 />} />
                <Route path="/visit14" element={<Visit14 />} />
                <Route path="/visit15" element={<Visit15 />} />
              </Route>
            </Routes>
          </Wrapper>
        </BrowserRouter>
      </CssBaseline>
    </PeriscopeContextControllerProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
