import * as React from "react";

import { Link } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const DrawerNavLink = {
  textDecoration: 'none',
}

export default function DrawerLink(props) {
  return (
    <ListItemButton>
      <Link to={props.link} style={DrawerNavLink}>
        <ListItemText
          primary={props.text}
          sx={{ color: "#4d4d4e" }}
          primaryTypographyProps={{
            fontSize: "1.25rem",
            fontFamily: "marvin-round",
          }}
        />
      </Link>
    </ListItemButton>
  );
}
