import { Grid } from "@mui/material";
import React, { useState } from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Icon5 from "../images/Icon5.png";
import Icon6 from "../images/Icon6.png";
import Icon7 from "../images/Icon7.png";
import Icon8 from "../images/Icon8.png";
import Icon9 from "../images/Icon9.png";
import Icon10 from "../images/Icon10.png";
import BackIcon from "../images/back-icon@2x.png";
import ListIcon from "../images/list-icon@2x.png";
import NextIcon from "../images/next-icon@2x.png";

import WavingGirl from "../images/study-clinic-visits-waving-girl@2x.png";

import "../layout.css";

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontSize: "2.5rem",
  color: "#f58020",
  margin: "0px",
};

const ResponsiveImg = {
  width: "75%",
  height: "auto",
};

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

export default function Visit8() {
  const { t } = useTranslation();

  const [visitPage, setVisitPage] = useState("");

  const navigate = useNavigate();

  const IconsRow1 = [
    {
      title: t("visit8-icon1-title"),
      details: t("visit8-icon1-details"),
      src: `${Icon1}`,
    },
    {
      title: t("visit8-icon2-title"),
      details: t("visit8-icon2-details"),
      src: `${Icon2}`,
    },
  ];

  return (
    <Grid
      container
      sx={{
        maxWidth: "1200px",
        m: "auto",
        backgroundColor: "#89c6e2",
        pb: "2rem",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "white",
            border: "5px dashed #333333",
            padding: ".5rem",
            textAlign: "center",
            paddingBottom: "1rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "brandon-grotesque",
              fontWeight: 700,
              fontSize: "2.5rem",
              color: "#f58020",
              margin: "0px",
              display: "inline",
            }}
          >
            {t("visit8-header-bold")}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Arial",
              fontSize: "2rem",
              color: "#f58020",
              display: "inline",
            }}
          >
            {t("visit8-header-parentheses")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: "1rem", maxWidth: { xs: "85%", sm: "70%" }, m: "auto" }}
      >
        <Grid container sx={{ mb: "2.5rem" }}>
          <Grid item xs={4} sm={4} sx={{ textAlign: "center" }}>
            <Link to="/visit7">
              <img src={BackIcon} />
            </Link>
          </Grid>
          <Grid item xs={4} sm={4} sx={{ textAlign: "center" }}>
            <Link to="/study-clinic-visits">
              <img src={ListIcon} />
            </Link>
          </Grid>
          <Grid item xs={4} sm={4} sx={{ textAlign: "center" }}>
            <Link to="/visit9">
              <img src={NextIcon} />
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {IconsRow1.map((a) => {
            return (
              <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
                <img alt="icon" src={a.src} style={ResponsiveImg} />
                <Accordion sx={{ textAlign: "left" }}>
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        fontFamily: "brandon-grotesque",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        color: "#1d3768",
                        lineHeight: "1.5rem",
                      }}
                    >
                      {a.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        borderTop: "3px solid #333333",
                        paddingTop: ".5rem",
                      }}
                    >
                      {a.details}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "left",
                backgroundColor: "#fff",
                border: "5px solid #000",
                padding: "1rem",
                paddingRight: "1.5rem",
                paddingLeft: "1.5rem",
                mx: "auto",
                mt: "2rem",
                mb: "2rem",
              }}
            >
              <Para>
                {t("visit8-bottom-box-para1")}
              </Para>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
