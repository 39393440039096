import * as React from "react";

import Grid from "@mui/material/Grid";
import "../../layout.css";
import HomeHeroArt from "../../images/home-hero-art.png";
import HeroHeader from "../typography/HeroHeader";
import { useTranslation } from "react-i18next";

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

export default function HomeHeroSm() {
  const { t } = useTranslation();

  return (
    <Grid container className="home-page-hero-sm">
      <Grid item xs={12} sx={{ textAlign: "center", marginBottom: "1rem" }}>
        <HeroHeader>{t("home-page-hero-lg-line1")}</HeroHeader>
        <HeroHeader>{t("home-page-hero-lg-line2")}</HeroHeader>
        <HeroHeader>{t("home-page-hero-lg-line3")}</HeroHeader>
      </Grid>
    </Grid>
  );
}
