// react imports
import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const PeriscopeContext = createContext();

// Setting custom name for the context which is visible on react dev tools
PeriscopeContext.displayName = "PeriscopeContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "LANGUAGE": {
      return { ...state, language: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const supportedLanguages = ["us_eng", "us_spa", "es_esp", "de_deu", "pl_pol"];

// Material Dashboard 2 React context provider
function PeriscopeContextControllerProvider({ children }) {
  const initialState = {
    language: "us_eng",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return (
    <PeriscopeContext.Provider value={value}>
      {children}
    </PeriscopeContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function usePeriscopeContextController() {
  const context = useContext(PeriscopeContext);

  if (!context) {
    throw new Error(
      "usePeriscopeContextController should be used inside the PeriscopeContextControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
PeriscopeContextControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setLanguage = (dispatch, value) => dispatch({ type: "LANGUAGE", value });

export {
  PeriscopeContextControllerProvider,
  usePeriscopeContextController,
  setLanguage,
};
