import { Grid } from "@mui/material";
import React from "react";
import PageHeaderBlack from "../components/typography/PageHeaderBlack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import HomeHeroLg from "../components/heroes/HomePageHeroLg";
import HomeHeroSm from "../components/heroes/HomePageHeroSm";
import WavingGuy from "../images/welcome-page-waving-guy@2x.png";
import SmilingGirl from "../images/welcome-page-smiling-girl@2x.png";
import Para from "../components/typography/Para";

import "../layout.css";

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "1.7rem",
  textAlign: "left",
  color: "#000",
  display: "inline-flex",
  lineHeight: "2rem",
  margin: 0,
};

export default function Welcome() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#89c6e2" }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "80%" },
          m: "auto",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className=""
          sx={{
            backgroundColor: "#14b0be",
            height: "150px",
            border: "5px dashed #1D3768",
          }}
        >
          <Typography
            sx={{
              paddingTop: "1rem",
              paddingLeft: "1rem",
              color: "White",
              fontFamily: "brandon-grotesque",
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            {t("welcome-page-blurb1-header")}
          </Typography>
          <Typography
            sx={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              color: "White",
              fontFamily: "Arial",
              fontSize: "1rem",
            }}
          >
            {t("welcome-page-blurb1-text")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ padding: { xs: "3rem", sm: "1rem" } }}>
          <img style={ResponsiveImg} alt="Family" src={WavingGuy} />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ padding: { xs: "3rem", sm: "1rem" } }}>
          <img style={ResponsiveImg} alt="Family" src={SmilingGirl} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className=""
          sx={{
            backgroundColor: "#fff",
            height: "150px",
            border: "5px dashed #000",
            marginTop: { xs: "1rem", sm: "10rem" },
          }}
        >
          <Typography
            sx={{
              paddingTop: "1.75rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              color: "#14b0be",
              fontFamily: "Arial",
              fontSize: "1rem",
            }}
          >
            {t("welcome-page-blurb2-text")}
          </Typography>
          <Typography
            sx={{
              paddingLeft: "1rem",
              color: "#3da3c3",
              fontFamily: "brandon-grotesque",
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            {t("welcome-page-blurb2-header")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#ffc423",
            border: "5px solid #333333",
            padding: "1rem",
          }}
        >
          <Para>{t("welcome-page-box-para1")}</Para>
          <br />
          <Para>{t("welcome-page-box-para2")}</Para>
          <br />
          <Para>{t("welcome-page-box-para3")}</Para>
          <br />
          <Para>{t("welcome-page-box-para4")}</Para>
          <br />
          <br />
          <Typography
            sx={{
              paddingLeft: "1rem",
              color: "black",
              fontFamily: "brandon-grotesque",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            {t("welcome-page-box-attribution")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
