import * as React from "react";

import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const Para = styled(Typography)`
  font-family: arial;
  font-size: 1rem;
  color: black;
`;

export default function Pagepara(props) {
  return <Para>{props.children}</Para>;
}
