import React, { useEffect, useState } from "react";
import MainNav from "./components/nav/MainNav";
import { Outlet } from "react-router-dom";
import i18next from "i18next";
import axios from "axios";
import Footer from "./components/nav/Footer";
import { useTranslation, initReactI18next } from "react-i18next";
import { usePeriscopeContextController, setLanguage } from "./context";
import Translations from "./translations/translations.json";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: Translations,
    supportedLngs: ["us_eng", "us_spa", "es_esp", "de_deu", "pl_pol"],
    fallbackLng: "us_eng",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const [controller, dispatch] = usePeriscopeContextController();
  const { language } = controller;

  let currentLanguageCode = i18next.language || "us_eng";

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("prg_title");
    console.log(Translations.us_spa.translation["contact-navlink"]);
    console.log(language);
  }, []);

  const supportedLanguages = {
    us_eng: {
      language: "us_eng",
      iso_country_code: "USA",
    },
    us_spa: {
      language: "us_spa",
      iso_country_code: "USA",
    },
    es_esp: {
      language: "es_esp",
      iso_country_code: "ESP",
    },
    de_deu: {
      language: "de_deu",
      iso_country_code: "DEU",
    },
    pl_pol: {
      language: "pl_pol",
      iso_country_code: "POL",
    },
  };

  // let country_code = null;
  let [country_code, setCountryCode] = useState(null);

  useEffect(() => {
    //Set Country Code based on UTM codes, if they are present
    let utm_code = new URLSearchParams(window.location.search).get(
      "utm_campaign"
    );

    // console.log("utm code: ", utm_code);
    if (utm_code in supportedLanguages) {
      let language = supportedLanguages[utm_code].language;
      console.log(language);
      i18next.changeLanguage(language);
      setLanguage(dispatch, language)
      setCountryCode(utm_code);
    } else {
      // Set Country Code based on IP address if UTM code is not found
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          for (let language in supportedLanguages) {
            let language_data = supportedLanguages[language];
            if (data.country_code_iso3 === language_data["iso_country_code"]) {
              i18next.changeLanguage(language_data.language);
              // console.log("language", language);
              setCountryCode(language);
              break;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div>
      <MainNav />
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
