import { Grid } from "@mui/material";
import React from "react";
import PageHeaderBlack from "../components/typography/PageHeaderBlack";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HomeHeroLg from "../components/heroes/HomePageHeroLg";
import HomeHeroSm from "../components/heroes/HomePageHeroSm";
import FamilyImage from "../images/homepage-img-1.png";
import Para from "../components/typography/Para";

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "1.7rem",
  textAlign: "left",
  color: "#000",
  display: "inline-flex",
  lineHeight: "2rem",
  margin: 0,
};

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#ffc423" }}
    >
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <HomeHeroLg />
      </Grid>
      <Grid container sx={{ display: { xs: "block", md: "none" } }}>
        <HomeHeroSm />
      </Grid>
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "80%" },
          m: "auto",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid item xs={9} sx={{ textAlign: "left", mx: "auto" }}>
          <PageHeaderBlack>
            <Link to="/welcome">{t("home-page-click-here-link")}</Link>{" "}
            {t("home-page-text")}
          </PageHeaderBlack>
          <br />
          <br />
        </Grid>
      </Grid>
    </Grid>
  );
}
