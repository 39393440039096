import { Grid } from "@mui/material";
import React, { useState } from "react";
import PageHero from "../components/heroes/PageHero";
import Para from "../components/typography/Para";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Icon1 from "../images/Icon1.png";
import Icon2 from "../images/Icon2.png";
import Icon3 from "../images/Icon3.png";
import Icon4 from "../images/Icon4.png";
import Icon5 from "../images/Icon5.png";
import Icon6 from "../images/Icon6.png";
import Icon7 from "../images/Icon7.png";
import Icon8 from "../images/Icon8.png";
import Icon9 from "../images/Icon9.png";
import Icon10 from "../images/Icon10.png";

import WavingGirl from "../images/study-clinic-visits-waving-girl@2x.png";

import "../layout.css";

const PageHeader = {
  fontFamily: "brandon-grotesque",
  fontWeight: 700,
  fontSize: "2.5rem",
  color: "#f58020",
  margin: "0px",
};

const ResponsiveImg = {
  width: "75%",
  height: "auto",
};

const ListItem = {
  fontFamily: "Arial",
  color: "#000",
  fontSize: "1rem",
};

export default function StudyClinicVisits() {
  const { t } = useTranslation();

  const [visitPage, setVisitPage] = useState("");

  const navigate = useNavigate();

  const VisitButtons = [
    {
      name: t("study-clinic-visits-button1"),
      to: "/visit1",
    },
    {
      name: t("study-clinic-visits-button2"),
      to: "/visit6",
    },
    {
      name: t("study-clinic-visits-button3"),
      to: "/visit11",
    },
    {
      name: t("study-clinic-visits-button4"),
      to: "/visit2",
    },
    {
      name: t("study-clinic-visits-button5"),
      to: "/visit7",
    },
    {
      name: t("study-clinic-visits-button6"),
      to: "/visit12",
    },
    {
      name: t("study-clinic-visits-button7"),
      to: "/visit3",
    },
    {
      name: t("study-clinic-visits-button8"),
      to: "/visit8",
    },
    {
      name: t("study-clinic-visits-button9"),
      to: "/visit13",
    },
    {
      name: t("study-clinic-visits-button10"),
      to: "/visit4",
    },
    {
      name: t("study-clinic-visits-button11"),
      to: "/visit9",
    },
    {
      name: t("study-clinic-visits-button12"),
      to: "/visit14",
    },
    {
      name: t("study-clinic-visits-button13"),
      to: "/visit5",
    },
    {
      name: t("study-clinic-visits-button14"),
      to: "/visit10",
    },
    {
      name: t("study-clinic-visits-button15"),
      to: "/visit15",
    },
  ];

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#89c6e2" }}
    >
      <Grid
        container
        sx={{
          maxWidth: { xs: "85%", sm: "70%" },
          m: "auto",
          paddingTop: "1rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "white",
            border: "5px solid #333333",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <h1 style={PageHeader}>{t("study-clinic-visits-header")}</h1>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: "1rem", maxWidth: { xs: "85%", sm: "70%" }, m: "auto" }}
      >
        <Grid item xs={8} sx={{ textAlign: "center" }}>
          <img className="responsive-img" src={WavingGirl} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            backgroundColor: "#3da3c3",
            height: "125px",
            border: "3px dashed #135e77",
            marginTop: { xs: "2rem", sm: "8rem" },
            marginBottom: "1rem",
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              padding: "1rem",
              color: "#fff",
              fontFamily: "Arial",
              fontWeight: 700,
              fontSize: "1.25rem",
            }}
          >
            {t("study-clinic-visits-blurb")}
          </Typography>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "left",
              backgroundColor: "#fff",
              border: "5px solid #000",
              padding: "1rem",
              paddingRight: "1.5rem",
              paddingLeft: "1.5rem",
              mx: "auto",
              mt: "2rem",
              mb: "2rem",
            }}
          >
            <Para>
              {t("study-clinic-visits-box-text-para1")}
            </Para>
            <br />
            <Para>
            {t("study-clinic-visits-box-text-para2")}
            </Para>
          </Grid>
          <Grid container sx={{paddingBottom: "3rem"}}>
            {VisitButtons.map((a) => {
              return (
                <Grid item xs={12} sm={4} sx={{ padding: ".5rem" }}>
                  <Link to={a.to} style={{textDecoration: 'none'}}>
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        backgroundColor: "#ffc423",
                        "&:hover": {
                          backgroundColor: "#f3ba1e",
                        },
                      }}
                      type="submit"
                    >
                      <Typography
                        sx={{
                          fontFamily: "brandon-grotesque",
                          fontWeight: 700,
                          fontSize: "1.5rem",
                          textTransform: "none",
                          color: "black",
                        }}
                      >
                        {a.name}
                      </Typography>
                    </Button>
                    </Link>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
